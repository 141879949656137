import {Localizations} from '~/lib/type';

export const countries: Localizations = {
  default: {
    label: 'Hong Kong (HKD $)',
    language: 'ZH_TW',
    country: 'HK',
    currency: 'HKD',
  },
  '/en': {
    label: 'Hong Kong (HKD $)',
    language: 'EN',
    country: 'HK',
    currency: 'HKD',
  },
};

export const hkLocales: Localizations = {
  default: {
    label: 'Hong Kong (HKD $)',
    language: 'ZH_TW',
    country: 'HK',
    currency: 'HKD',
  },
  '/en': {
    label: 'Hong Kong (HKD $)',
    language: 'EN',
    country: 'HK',
    currency: 'HKD',
  },
};

export const twLocales: Localizations = {
  default: {
    label: 'Taiwan (TWD $)',
    language: 'ZH_TW',
    country: 'TW',
    currency: 'TWD',
  },
  '/en': {
    label: 'Taiwan (TWD $)',
    language: 'EN',
    country: 'TW',
    currency: 'TWD',
  },
};
